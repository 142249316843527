// Generated by Framer (b0f2619)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/XKflbPIlW";

const cycleOrder = ["I502:10211;455:3668"];

const serializationHash = "framer-DBNCP"

const variantClassNames = {"I502:10211;455:3668": "framer-v-10dr0z1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({badge, height, id, width, ...props}) => { return {...props, Put_sXBvP: badge ?? props.Put_sXBvP ?? "Health"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;badge?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Put_sXBvP, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I502:10211;455:3668", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10dr0z1", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"I502:10211;455:3668"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c8457849-7686-49ca-bde2-a1b189812cc2, rgb(192, 204, 155))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1t3028v"} data-styles-preset={"XKflbPIlW"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-874b1b5d-94a7-4bd8-9a23-54b9dc1195b6, rgb(68, 82, 24)))"}}>Santé</motion.p></React.Fragment>} className={"framer-s7cct6"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"I502:10211;455:3668;455:2758"} style={{"--extracted-r6o4lv": "var(--token-874b1b5d-94a7-4bd8-9a23-54b9dc1195b6, rgb(68, 82, 24))", "--framer-paragraph-spacing": "14px"}} text={Put_sXBvP} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-DBNCP[data-border=\"true\"]::after, .framer-DBNCP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DBNCP.framer-df940m, .framer-DBNCP .framer-df940m { display: block; }", ".framer-DBNCP.framer-10dr0z1 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 2px 10px 2px 10px; position: relative; width: min-content; }", ".framer-DBNCP .framer-s7cct6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DBNCP.framer-10dr0z1 { gap: 0px; } .framer-DBNCP.framer-10dr0z1 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-DBNCP.framer-10dr0z1 > :first-child { margin-left: 0px; } .framer-DBNCP.framer-10dr0z1 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Put_sXBvP":"badge"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramergmUx2G90x: React.ComponentType<Props> = withCSS(Component, css, "framer-DBNCP") as typeof Component;
export default FramergmUx2G90x;

FramergmUx2G90x.displayName = "Badge";

FramergmUx2G90x.defaultProps = {height: 25, width: 68};

addPropertyControls(FramergmUx2G90x, {Put_sXBvP: {defaultValue: "Health", displayTextArea: false, placeholder: "Health", title: "Badge", type: ControlType.String}} as any)

addFonts(FramergmUx2G90x, [...sharedStyle.fonts])